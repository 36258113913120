<template>
  <div class="contract-expiration-component">
    <div class="title-line">
      <img
        class="title-img"
        src="@/assets/images/bms/contract-expiration-icon.png"
        alt=""
      />
      <span class="label-span"> 合同到期提醒 </span>
    </div>
    <div v-loading="listLoading">
      <div v-if="noticeList.length">
        <div class="detail-container">
          <div
            class="single-notice"
            v-for="(item, index) in noticeList"
            :key="index"
            @click="jumpToRspace(item)"
          >
            <span class="tag-span">
              {{ item.store }}
            </span>
            <span class="label-span">
              {{ `${item.source} ${item.tenant}` }}
            </span>
            <span class="date-span">
              {{ `剩余${item.daysLeft}天` }}
            </span>
          </div>
        </div>
        <div class="view-all-line">
          <ViewAllButton :count="currentTotal" @click="viewAllHandler" />
        </div>
      </div>
      <div v-else>
        <el-empty description="暂无数据"></el-empty>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getProjectList,
  getExpirationList,
} from "@/api/ruge/bms/integratedManagementPlatform/workingStaging.js";
import ViewAllButton from "../../components/ViewAllButton";
import { rspacePathMap } from "@/constants/constant.js";
export default {
  name: "contractExpirationComponent",
  components: {
    ViewAllButton,
  },
  data() {
    return {
      listLoading: false,
      currentTotal: 0,
      noticeList: [],
    };
  },
  created() {
    this.initDatas();
  },
  methods: {
    jumpToRspace({ leaseId, storeId }) {
      const url = `${
        rspacePathMap[process.env.VUE_APP_ENV]
      }/contract/contractDetial/${leaseId}?sid=${storeId}`;
      window.open(url, "_blank");
    },
    async initDatas() {
      this.listLoading = true;
      try {
        const projectList = await getProjectList();
        const expirationList = await getExpirationList({
          store_id: projectList.map((item) => item.projectId),
          days_left: 90,
          sort: "-leaseenddate",
          token: this.$store.getters.baseAuthToken,
        });
        const { data } = expirationList;
        this.currentTotal = data.total;
        this.noticeList = data.list.slice(0, 10);
      } finally {
        this.listLoading = false;
      }
    },
    viewAllHandler() {
      const url = `${
        rspacePathMap[process.env.VUE_APP_ENV]
      }/contract/leaseContract?type=2`;
      window.open(url, "_blank");
    },
  },
};
</script>

<style lang="less" scoped>
.contract-expiration-component {
  background: #ffffff;
  border-radius: 4px;
  padding: 24px;
  height: 517px;
  .title-line {
    display: flex;
    align-items: center;
    .title-img {
      margin-right: 8px;
    }
    .label-span {
      font-size: 16px;
      font-weight: bold;
      color: #3b454c;
    }
  }
  .detail-container {
    margin-top: 24px;
    border-top: 1px solid #ebeef5;
    height: 375px;
    margin-bottom: 15px;
    padding-top: 10px;
    .single-notice {
      display: flex;
      align-items: center;
      height: 37px;
      line-height: 37px;
      .tag-span {
        display: inline-block;
        width: 120px;
        height: 24px;
        line-height: 24px;
        text-align: center;
        background: #ebeef5;
        border-radius: 2px;
        color: #3b454c;
        font-size: 14px;
        font-weight: 400;
        margin-right: 8px;
      }
      .label-span {
        font-size: 14px;
        font-weight: 500;
        color: #1a4cec;
        cursor: pointer;
        flex: 1;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .date-span {
        font-size: 14px;
        font-weight: 400;
        color: #9099a9;
      }
    }
  }
}
</style>