var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "order-device-list" }, [
    _c("div", { staticClass: "title-container" }, [
      _vm._m(0),
      _c("div", { staticClass: "count-line" }, [
        _vm._v("\n      " + _vm._s(_vm.currentTotal) + "\n    "),
      ]),
    ]),
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.listLoading,
            expression: "listLoading",
          },
        ],
        staticClass: "detail-container",
      },
      _vm._l(_vm.orderList, function (item, index) {
        return _c(
          "div",
          { key: index, staticClass: "single-detail" },
          [
            _c("span", { staticClass: "tag-span" }, [
              _vm._v(_vm._s(item.orderStatusName) + " "),
            ]),
            _c(
              "span",
              {
                staticClass: "detail-span",
                on: {
                  click: function ($event) {
                    return _vm.jumpToDetail(item)
                  },
                },
              },
              [_vm._v("\n        " + _vm._s(item.problemDesc || "-"))]
            ),
            item.orderStatus === 1
              ? _c("span", { staticClass: "date-span" }, [
                  _vm._v("\n        " + _vm._s(_vm.$t("order.unabsorbed"))),
                ])
              : item.orderStatus === 6
              ? [
                  item.isSelf == "Y"
                    ? _c("span", { staticClass: "date-span" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              `${item.submitUserName} ${item.submitUserDeptName}`
                            ) +
                            "\n        "
                        ),
                      ])
                    : _c("span", { staticClass: "date-span" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("order.unabsorbed")) +
                            "\n        "
                        ),
                      ]),
                ]
              : _c("span", { staticClass: "date-span" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(`${item.handlePersonName}/${item.resDeptName}`)
                  ),
                ]),
            _c("span", { staticClass: "date-span" }, [
              _vm._v(" " + _vm._s(_vm.deadLineRender(item))),
            ]),
          ],
          2
        )
      }),
      0
    ),
    _c(
      "div",
      { staticClass: "view-all-line" },
      [
        _c("ViewAllButton", {
          attrs: { count: _vm.currentTotal },
          on: { click: _vm.viewAllHandler },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title-line" }, [
      _c("img", {
        attrs: {
          src: require("@/assets/images/bms/order-list-icon.png"),
          alt: "",
        },
      }),
      _c("span", { staticClass: "label-span" }, [_vm._v(" 工单超时提醒 ")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }