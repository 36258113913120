var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "inspection-list-component" }, [
    _c("div", { staticClass: "title-container" }, [
      _vm._m(0),
      _c("div", { staticClass: "count-line" }, [
        _vm._v("\n      " + _vm._s(_vm.currentTotal) + "\n    "),
      ]),
    ]),
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.listLoading,
            expression: "listLoading",
          },
        ],
        staticClass: "detail-container",
      },
      _vm._l(_vm.inspectionList, function (item, index) {
        return _c("div", { key: index, staticClass: "single-detail" }, [
          _c("span", { staticClass: "tag-span" }, [
            _vm._v(_vm._s(item.statusName) + " "),
          ]),
          _c(
            "span",
            {
              staticClass: "detail-span",
              on: {
                click: function ($event) {
                  return _vm.jumpToInspectiondetail(item)
                },
              },
            },
            [_vm._v("\n        " + _vm._s(item.inspectionJobName))]
          ),
          _c("span", { staticClass: "date-span" }, [
            _vm._v(
              "\n        " +
                _vm._s(
                  item.handlePersonName
                    ? `${item.handlePersonName}/${item.deptName}`
                    : "未分配"
                )
            ),
          ]),
          _c("span", { staticClass: "date-span" }, [
            _vm._v(" " + _vm._s(_vm.timeDiffRender(item))),
          ]),
        ])
      }),
      0
    ),
    _c(
      "div",
      { staticClass: "view-all-line" },
      [
        _c("ViewAllButton", {
          attrs: { count: _vm.currentTotal },
          on: { click: _vm.viewAllHandler },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title-line" }, [
      _c("img", {
        attrs: {
          src: require("@/assets/images/bms/inspection-list-icon.png"),
          alt: "",
        },
      }),
      _c("span", { staticClass: "label-span" }, [_vm._v(" 巡检超时提醒 ")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }