var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "smart-device-list" }, [
    _c("div", { staticClass: "title-container" }, [
      _vm._m(0),
      _c("div", { staticClass: "count-line" }, [
        _vm._v("\n      " + _vm._s(_vm.currentTotal) + "\n    "),
      ]),
    ]),
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.listLoading,
            expression: "listLoading",
          },
        ],
        staticClass: "detail-container",
      },
      _vm._l(_vm.deviceList, function (item, index) {
        return _c(
          "div",
          {
            key: index,
            staticClass: "single-detail",
            on: {
              click: function ($event) {
                return _vm.jumpToAlarmDetail(item)
              },
            },
          },
          [
            _c(
              "span",
              {
                staticClass: "tag-span",
                style: {
                  background:
                    item.alarmStatus === "normal" ? "#E9FFEB" : "#FFF7E9",
                  color: item.alarmStatus === "normal" ? "#4ED7B6" : "#D7A24E",
                },
              },
              [
                _vm._v(
                  _vm._s(item.alarmStatus === "normal" ? "恢复" : "告警") +
                    "\n      "
                ),
              ]
            ),
            _c("span", { staticClass: "detail-span" }, [
              _vm._v(" " + _vm._s(item.alarmContent)),
            ]),
            _c("span", { staticClass: "date-span" }, [
              _vm._v(" " + _vm._s(`持续${_vm.timeDiffRender(item)}`)),
            ]),
          ]
        )
      }),
      0
    ),
    _c(
      "div",
      { staticClass: "view-all-line" },
      [
        _c("ViewAllButton", {
          attrs: { count: _vm.currentTotal },
          on: { click: _vm.viewAllHandler },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title-line" }, [
      _c("img", {
        attrs: {
          src: require("@/assets/images/bms/smart-device-icon.png"),
          alt: "",
        },
      }),
      _c("span", { staticClass: "label-span" }, [_vm._v(" 智能设备监测 ")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }