<template>
  <div class="view-all-button" @click="$emit('click')">
    {{ `查看全部(${count})` }}
  </div>
</template>

<script>
export default {
  name: "viewAllButton",
  props: {
    count: {
      type: Number,
      default() {
        return 0;
      },
    },
  },
};
</script>

<style lang="less" scoped>
.view-all-button {
  width: 100%;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 4px;
  border: 1px solid #e4e7ed;
  cursor: pointer;
}
</style>