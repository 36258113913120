<template>
  <div class="inspection-list-component">
    <div class="title-container">
      <div class="title-line">
        <img src="@/assets/images/bms/inspection-list-icon.png" alt="" />
        <span class="label-span"> 巡检超时提醒 </span>
      </div>
      <div class="count-line">
        {{ currentTotal }}
      </div>
    </div>
    <div class="detail-container" v-loading="listLoading">
      <div
        class="single-detail"
        v-for="(item, index) in inspectionList"
        :key="index"
      >
        <span class="tag-span">{{ item.statusName }} </span>
        <span class="detail-span" @click="jumpToInspectiondetail(item)">
          {{ item.inspectionJobName }}</span
        >
        <span class="date-span">
          {{
            item.handlePersonName
              ? `${item.handlePersonName}/${item.deptName}`
              : "未分配"
          }}</span
        >
        <span class="date-span"> {{ timeDiffRender(item) }}</span>
      </div>
    </div>
    <div class="view-all-line">
      <ViewAllButton :count="currentTotal" @click="viewAllHandler" />
    </div>
  </div>
</template>

<script>
import { selectOvertimeInspection } from "@/api/ruge/bms/integratedManagementPlatform/workingStaging.js";
import { differenceInDays, intervalToDuration, addMinutes } from "date-fns";
import ViewAllButton from "../../components/ViewAllButton";
export default {
  name: "inspectionListComponent",
  components: {
    ViewAllButton,
  },
  data() {
    return {
      listLoading: false,
      currentTotal: 0,
      inspectionList: [],
    };
  },
  created() {
    this.initDatas();
  },
  methods: {
    jumpToInspectiondetail({ inspectionJobId }) {
      const url = `${location.origin}${location.pathname}#/tenant/inspection/inspectionSearch/detail?inspectionJobId=${inspectionJobId}`;
      window.open(url, "_blank");
    },
    checkExpire(item) {
      // 任务状态（1：待领取，2：待执行，3：执行中，4：已完成）
      switch (item.status) {
        case 1:
          ///** 待领取 */
          if (item.startTime) {
            const unclaimedOvertime =
              typeof item.unclaimedOvertime === "number"
                ? item.unclaimedOvertime
                : 0;
            const s = new Date(item.startTime).getTime();
            const e = s + unclaimedOvertime;
            const n = new Date();
            return n.getTime() > e;
          }
          break;
        case 2:
          /** 待执行 */
          if (item.lastUpdateDate) {
            const firstExecutedOvertime =
              typeof item.firstExecutedOvertime === "number"
                ? item.firstExecutedOvertime
                : 0;
            const s = new Date(item.lastUpdateDate).getTime();
            const e = s + firstExecutedOvertime;
            const n = new Date();
            return n.getTime() > e;
          }
          break;
        case 2:
          /** 执行中 */
          if (item.endTime) {
            const e = new Date(item.endTime);
            const n = new Date();
            return n.getTime() > e.getTime();
          }
          break;
      }
    },
    timeDiffRender(item) {
      // 任务状态（1：待领取，2：待执行，3：执行中，4：已完成）
      let str = "";
      let i = null;
      if (typeof item.status === "number") {
        switch (item.status) {
          case 2:
            // if (this.checkExpire(item)) {
            // if (typeof item.lastUpdateDate === "number") {
            const firstExecutedOvertime =
              typeof item.firstExecutedOvertime === "number"
                ? item.firstExecutedOvertime
                : 0;
            const s = new Date(item.lastUpdateDate);
            const expire = addMinutes(s, firstExecutedOvertime);
            i = {
              start: new Date(expire),
              end: new Date(),
            };
            str += "超时";
            // }
            // } else {
            //   if (item.endTime) {
            //     i = {
            //       start: new Date(),
            //       end: new Date(item.endTime),
            //     };
            //     str += "剩余";
            //   }
            // }
            break;
          case 3:
            // if (this.checkExpire(item)) { 
            if (item.endTime) {
              i = {
                start: new Date(item.endTime),
                end: new Date(),
              };
              str += "超时";
            }
            // } else {
            //   if (item.endTime) {
            //     i = {
            //       start: new Date(),
            //       end: new Date(item.endTime),
            //     };
            //     str += "剩余";
            //   }
            // }
            break;
          case 1:
            // if (this.checkExpire(item)) {
            if (item.creationDate) {
              const unclaimedOvertime =
                typeof item.unclaimedOvertime === "number"
                  ? item.unclaimedOvertime
                  : 0;
              const s = new Date(item.creationDate).getTime();
              const expire = addMinutes(s, unclaimedOvertime);
              i = {
                start: new Date(expire),
                end: new Date(),
              };
              str += "超时";
            }
            // } else {
            //   if (item.startTime) {
            //     i = {
            //       start: new Date(item.startTime),
            //       end: new Date(),
            //     };
            //     str += "已生成";
            //   }
            // }
            break;
        }
      }

      if (i) {
        const m = new Date(i.end).getTime - new Date(i.start).getTime();
        if (m < 1) {
          str += "0分";
        } else {
          const d = intervalToDuration(i);
          const days = differenceInDays(i.end, i.start);

          if (days > 0) {
            str += `${days}天`;
          }
          if (d.hours && d.hours > 0) {
            str += `${d.hours}小时`;
          }
          if (d.minutes && d.minutes > 0) {
            str += `${d.minutes}分`;
          }
        }
      }

      return str;
    },
    // timeDiffRender(startDate) {
    //   const endDate = new Date().getTime();
    //   let date3 = endDate - startDate; //时间差的毫秒数
    //   //计算出相差天数
    //   let days = Math.floor(date3 / (24 * 3600 * 1000));
    //   //计算出小时数
    //   let leave1 = date3 % (24 * 3600 * 1000); //计算天数后剩余的毫秒数
    //   let hours = Math.floor(leave1 / (3600 * 1000));
    //   //计算相差分钟数
    //   let leave2 = leave1 % (3600 * 1000); //计算小时数后剩余的毫秒数
    //   let minutes = Math.floor(leave2 / (60 * 1000));
    //   //计算相差秒数
    //   let leave3 = leave2 % (60 * 1000); //计算分钟数后剩余的毫秒数
    //   let seconds = Math.round(leave3 / 1000);
    //   if (days || hours || minutes) {
    //     return (
    //       (days > 0 ? days + "天" : "") +
    //       (hours > 0 ? hours + "小时" : "") +
    //       (minutes > 0 ? minutes + "分" : "")
    //     );
    //   } else {
    //     return 0 + "分";
    //   }
    // },
    initDatas() {
      this.listLoading = true;
      selectOvertimeInspection()
        .then((res) => {
          this.inspectionList = res.inspectionList;
          this.currentTotal = res.inspectionCount;
        })
        .finally(() => {
          this.listLoading = false;
        });
    },
    viewAllHandler() {
      const url = `${location.origin}${location.pathname}#/tenant/inspection/inspectionSearch/list?isOverTime=Y`;
      window.open(url, "_blank");
    },
  },
};
</script>

<style lang="less" scoped>
.inspection-list-component {
  padding: 24px;
  .title-container {
    .title-line {
      display: flex;
      align-items: center;
      .label-span {
        font-size: 16px;
        font-weight: bold;
        color: #3b454c;
        margin-left: 8px;
      }
    }
    .count-line {
      margin-top: 16px;
      margin-bottom: 24px;
      font-size: 40px;
      font-weight: bold;
      color: #3b454c;
    }
  }
  .detail-container {
    padding-top: 15px;
    border-top: 1px solid #ebeef5;
    height: 375px;
    margin-bottom: 10px;
    overflow: auto;
    .single-detail {
      height: 35px;
      line-height: 35px;
      display: flex;
      align-items: center;
      .tag-span {
        display: inline-block;
        width: 48px;
        height: 24px;
        line-height: 24px;
        text-align: center;
        margin-right: 8px;
        border-radius: 4px;
        font-size: 14px;
        background: #fff7e9;
        color: #d7a24e;
      }
      .detail-span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        flex: 1;
        font-size: 14px;
        font-weight: 500;
        color: #1a4cec;
        cursor: pointer;
      }
      .date-span {
        font-size: 14px;
        font-weight: 400;
        color: #9099a9;
      }
      .date-span + .date-span {
        margin-left: 8px;
      }
    }
  }
}
</style>