import request from '@/utils/request';
import { envInfo } from '@/constants/envInfo';

/**
 * banner list - 个人日程查询
 * @param params
 */
export function getPersonalSchedule(params) {
  return request({
    url: envInfo.bgApp.bmsPath + '/schedule/findScheduleByMonth',
    method: 'get',
    params,
  });
}

/**
 * 租赁情况
 * @param params
 */
export function findProjectRentalByMonth(params) {
  return request({
    url: envInfo.bgApp.bmsPath + '/rental/findProjectRentalByMonth',
    method: 'get',
    params,
  });
}

/**
 * 工单超时提醒
 * @param params
 */
export function selectOvertimeOrder(params) {
  return request({
    url: envInfo.bgApp.bmsPath + '/rworkData/selectOvertimeOrder',
    method: 'get',
    params,
  });
}

/**
 * 巡检超时提醒
 * @param params
 */
export function selectOvertimeInspection(params) {
  return request({
    url: envInfo.bgApp.bmsPath + '/rworkData/selectOvertimeInspection',
    method: 'get',
    params,
  });
}

/**
 * 获取project集合
 * @param params
 */
export function getProjectList(params) {
  return request({
    url: envInfo.bgApp.bmsPath + '/tenantToProject/getProjectList',
    method: 'get',
    params,
  });
}

/**
 * 获取到期合同list
 * @param params
 */
export function getExpirationList(params) {
  return request({
    url: '/api/rx_lease/inner/expiring_soon',
    method: 'get',
    params,
  });
}
