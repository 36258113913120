import request from '@/utils/request';
import {
  envInfo
} from '@/constants/envInfo';

/**
 * 告警分页查询
 * @param params
 */
export function findAlarmPage(params) {
  return request({
    url: envInfo.bgApp.dorisPath + '/rdata/alarm/page',
    method: 'get',
    params,
  });
}

/**
 * 告警分页查询 - doris
 * @param params
 */
export function findAlarmPageDoris(params) {
  return request({
    url: envInfo.bgApp.dorisPath + '/rdata/alarm/page',
    method: 'get',
    params,
  });
}

/**
 * 获取告警详情
 * @param params
 */
export function findAlarmDetail(params) {
  return request({
    url: envInfo.bgApp.dorisPath + '/rdata/alarm/detail',
    method: 'get',
    params,
  });
}

/**
 * 获取告警详情 - doris
 * @param params
 */
export function findAlarmDetailDoris(params) {
  return request({
    url: envInfo.bgApp.dorisPath + '/rdata/alarm/detail',
    method: 'get',
    params,
  });
}