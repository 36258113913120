<template>
  <div class="smart-device-list">
    <div class="title-container">
      <div class="title-line">
        <img src="@/assets/images/bms/smart-device-icon.png" alt="" />
        <span class="label-span"> 智能设备监测 </span>
      </div>
      <div class="count-line">
        {{ currentTotal }}
      </div>
    </div>
    <div class="detail-container" v-loading="listLoading">
      <div
        class="single-detail"
        v-for="(item, index) in deviceList"
        :key="index"
        @click="jumpToAlarmDetail(item)"
      >
        <span
          class="tag-span"
          :style="{
            background: item.alarmStatus === 'normal' ? '#E9FFEB' : '#FFF7E9',
            color: item.alarmStatus === 'normal' ? '#4ED7B6' : '#D7A24E',
          }"
          >{{ item.alarmStatus === "normal" ? "恢复" : "告警" }}
        </span>
        <span class="detail-span"> {{ item.alarmContent }}</span>
        <span class="date-span"> {{ `持续${timeDiffRender(item)}` }}</span>
      </div>
    </div>
    <div class="view-all-line">
      <ViewAllButton :count="currentTotal" @click="viewAllHandler" />
    </div>
  </div>
</template>

<script>
import { findAlarmPage } from "@/api/ruge/vlink/alarm/alarm";
import ViewAllButton from "../../components/ViewAllButton";
export default {
  name: "smartDeviceList",
  components: {
    ViewAllButton,
  },
  data() {
    return {
      listLoading: false,
      currentTotal: 0,
      deviceList: [],
    };
  },
  created() {
    this.initDatas();
  },
  methods: {
    jumpToAlarmDetail({ alarmUUID }) {
      const url = `${location.origin}${location.pathname}#/iot/alarm/detail?alarmId=${alarmUUID}`;
      window.open(url, "_blank");
    },
    timeDiffRender({ alarmTimestamp, normalTimestamp }) {
      const endDate = normalTimestamp || new Date().getTime();
      let date3 = endDate - alarmTimestamp; //时间差的毫秒数
      //计算出相差天数
      let days = Math.floor(date3 / (24 * 3600 * 1000));
      //计算出小时数
      let leave1 = date3 % (24 * 3600 * 1000); //计算天数后剩余的毫秒数
      let hours = Math.floor(leave1 / (3600 * 1000));
      //计算相差分钟数
      let leave2 = leave1 % (3600 * 1000); //计算小时数后剩余的毫秒数
      let minutes = Math.floor(leave2 / (60 * 1000));
      //计算相差秒数
      let leave3 = leave2 % (60 * 1000); //计算分钟数后剩余的毫秒数
      let seconds = Math.round(leave3 / 1000);
      if (days || hours || minutes) {
        return (
          (days > 0 ? days + "天" : "") +
          (hours > 0 ? hours + "小时" : "") +
          (minutes > 0 ? minutes + "分" : "")
        );
      } else {
        return 0 + "分";
      }
    },
    initDatas() {
      this.listLoading = true;
      findAlarmPage({
        current: 1,
        rowCount: 10,
      })
        .then((res) => {
          this.deviceList = res.rows;
          this.currentTotal = res.total;
        })
        .finally(() => {
          this.listLoading = false;
        });
    },
    viewAllHandler() {
      const url = `${location.origin}${location.pathname}#/iot/alarm/list`;
      window.open(url, "_blank");
    },
  },
};
</script>

<style lang="less" scoped>
.smart-device-list {
  padding: 24px;
  .title-container {
    .title-line {
      display: flex;
      align-items: center;
      .label-span {
        font-size: 16px;
        font-weight: bold;
        color: #3b454c;
        margin-left: 8px;
      }
    }
    .count-line {
      margin-top: 16px;
      margin-bottom: 24px;
      font-size: 40px;
      font-weight: bold;
      color: #3b454c;
    }
  }
  .detail-container {
    padding-top: 15px;
    border-top: 1px solid #ebeef5;
    height: 375px;
    margin-bottom: 10px;
    overflow: auto;
    .single-detail {
      height: 35px;
      line-height: 35px;
      display: flex;
      align-items: center;
      .tag-span {
        display: inline-block;
        width: 48px;
        height: 24px;
        line-height: 24px;
        text-align: center;
        margin-right: 8px;
        border-radius: 4px;
        font-size: 14px;
      }
      .detail-span {
        flex: 1;
        width: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 14px;
        font-weight: 500;
        color: #1a4cec;
        cursor: pointer;
      }
      .date-span {
        font-size: 14px;
        font-weight: 400;
        color: #9099a9;
      }
    }
  }
}
</style>