<template>
  <div class="order-device-list">
    <div class="title-container">
      <div class="title-line">
        <img src="@/assets/images/bms/order-list-icon.png" alt="" />
        <span class="label-span"> 工单超时提醒 </span>
      </div>
      <div class="count-line">
        {{ currentTotal }}
      </div>
    </div>
    <div class="detail-container" v-loading="listLoading">
      <div
        class="single-detail"
        v-for="(item, index) in orderList"
        :key="index"
      >
        <span class="tag-span">{{ item.orderStatusName }} </span>
        <span class="detail-span" @click="jumpToDetail(item)">
          {{ item.problemDesc || "-" }}</span
        >
        <span class="date-span" v-if="item.orderStatus === 1">
          {{ $t("order.unabsorbed") }}</span
        >
        <template v-else-if="item.orderStatus === 6">
          <span class="date-span" v-if="item.isSelf == 'Y'">
            {{ `${item.submitUserName} ${item.submitUserDeptName}` }}
          </span>
          <span class="date-span" v-else>
            {{ $t("order.unabsorbed") }}
          </span>
        </template>
        <span class="date-span" v-else>
          {{ `${item.handlePersonName}/${item.resDeptName}` }}</span
        >
        <span class="date-span"> {{ deadLineRender(item) }}</span>
        <!-- <span class="date-span"> 2022-21-23</span> -->
        <!-- <template v-if="item.orderStatus === 1">
          <span class="submit-user-span">{{ $t("order.unabsorbed") }}</span>
        </template>
        <template v-else-if="item.orderStatus === 6">
          <div v-if="item.isSelf == 'Y'">
            <div class="submit-user-span">
              {{ item.submitUserName }}
            </div>
            <div class="submit-dept-span">
              {{ item.submitUserDeptName }}
            </div>
          </div>
          <span class="submit-user-span" v-else>{{
            $t("order.unabsorbed")
          }}</span>
        </template>
        <template v-else>
          <div>
            <div class="submit-user-span">
              {{ item.handlePersonName }}
            </div>
            <div class="submit-dept-span">
              {{ item.resDeptName }}
            </div>
          </div>
        </template> -->
      </div>
    </div>
    <div class="view-all-line">
      <ViewAllButton :count="currentTotal" @click="viewAllHandler" />
    </div>
  </div>
</template>

<script>
import { selectOvertimeOrder } from "@/api/ruge/bms/integratedManagementPlatform/workingStaging.js";
import ViewAllButton from "../../components/ViewAllButton";
// const OrderStatus = {
//   1: "待领取",
//   2: "待执行",
//   3: "执行中",
//   4: "待验收",
//   5: "已完成",
//   6: "报单被驳回",
//   7: "验收被驳回",
//   9: "已关单",
// };
export default {
  name: "orderDeviceList",
  components: {
    ViewAllButton,
  },
  data() {
    return {
      // OrderStatus,
      listLoading: false,
      currentTotal: 0,
      orderList: [],
    };
  },
  created() {
    this.initDatas();
  },
  methods: {
    jumpToDetail({ orderId }) {
      const url = `${location.origin}${location.pathname}#/iot/orderQuery/detail?orderId=${orderId}`;
      window.open(url, "_blank");
    },
    deadLineRender(data) {
      const state = data.orderStatus;
      switch (String(state)) {
        case "0":
          if (!data.creationDateStr) {
            return "";
          }
          return (
            "(已生成" +
            this.timediff(
              new Date(this.toIOSDateString(data.creationDateStr)),
              new Date()
            ) +
            ")"
          );
        case "1":
          if (
            !data.orderDynamicList[0].creationDateStr ||
            !data.creationDateStr ||
            data.unclaimedNumber == null ||
            data.unclaimedOvertime == null
          ) {
            return "";
          }
          if (data.unclaimedNumber > 0) {
            // 超时
            const examine = data.orderDynamicList[0].creationDateStr;
            const formatExamine = new Date(this.toIOSDateString(examine));
            const examineMinute = new Date(
              formatExamine.setMinutes(
                formatExamine.getMinutes() + data.unclaimedOvertime
              )
            );
            return "超时" + this.timediff(examineMinute, new Date());
          } else {
            // 未超时
            return (
              "(已生成" +
              this.timediff(
                new Date(this.toIOSDateString(data.creationDateStr)),
                new Date()
              ) +
              ")"
            );
          }
        case "2":
          if (
            !data.requestDateStr ||
            !data.orderDynamicList[0].creationDateStr ||
            data.executedNumber == null ||
            data.firstExecutedOvertime == null
          ) {
            return "";
          }
          if (data.executedNumber > 0) {
            // 计算超时时间-ych
            let receiveMinute;
            let that = this;
            function getTimeByStatus(statusKey) {
              data.orderDynamicList.map((it) => {
                if (it.operationType === statusKey) {
                  if (data.executedNumber) {
                    const formatReceive = new Date(
                      that.toIOSDateString(it.creationDateStr)
                    );
                    receiveMinute = new Date(
                      formatReceive.setMinutes(
                        formatReceive.getMinutes() + data.firstExecutedOvertime
                      )
                    );
                  }
                }
              });
            }
            let findArr = ["receive", "report_audit_ok", "create"];
            findArr.map((its) => {
              if (!receiveMinute) getTimeByStatus(its);
            });
            if (!receiveMinute) return "";
            return "超时" + this.timediff(receiveMinute, new Date());
          } else {
            // 未超时
            return (
              "(剩余" +
              this.timediff(
                new Date(),
                new Date(this.toIOSDateString(data.requestDateStr))
              ) +
              ")"
            );
          }
        case "3":
        case "7":
          if (!data.requestDateStr) {
            return "";
          }
          if (
            new Date().getTime() <
            new Date(this.toIOSDateString(data.requestDateStr)).getTime()
          ) {
            // 未超时
            return (
              "(剩余" +
              this.timediff(
                new Date(),
                new Date(this.toIOSDateString(data.requestDateStr))
              ) +
              ")"
            );
          } else {
            // 超时
            return (
              "超时" +
              this.timediff(
                new Date(this.toIOSDateString(data.requestDateStr)),
                new Date()
              )
            );
          }
        case "4":
          if (
            !data.orderDynamicList[0].creationDateStr ||
            !data.creationDateStr
          ) {
            return "";
          }
          const inspect = data.orderDynamicList[0].creationDateStr;
          const formatInspect = new Date(this.toIOSDateString(inspect));
          return (
            "(耗时" +
            this.timediff(
              new Date(this.toIOSDateString(data.creationDateStr)),
              formatInspect
            ) +
            ")"
          );
        case "5":
          if (!data.completeDateStr || !data.creationDateStr) {
            return "";
          }
          return (
            "(用时" +
            this.timediff(
              new Date(this.toIOSDateString(data.creationDateStr)),
              new Date(this.toIOSDateString(data.completeDateStr))
            ) +
            ")"
          );
        case "6":
          if (!data.lastUpdateDateStr || !data.creationDateStr) {
            return "";
          }
          return (
            "(耗时" +
            this.timediff(
              new Date(this.toIOSDateString(data.creationDateStr)),
              new Date(this.toIOSDateString(data.lastUpdateDateStr))
            ) +
            ")"
          );
        default:
          return "";
      }
    },
    toIOSDateString(s) {
      return s.replace(/-/g, "/") || undefined;
    },
    timediff(startDate, endDate) {
      let date3 = endDate.getTime() - startDate.getTime(); //时间差的毫秒数
      //计算出相差天数
      let days = Math.floor(date3 / (24 * 3600 * 1000));
      //计算出小时数
      let leave1 = date3 % (24 * 3600 * 1000); //计算天数后剩余的毫秒数
      let hours = Math.floor(leave1 / (3600 * 1000));
      //计算相差分钟数
      let leave2 = leave1 % (3600 * 1000); //计算小时数后剩余的毫秒数
      let minutes = Math.floor(leave2 / (60 * 1000));
      //计算相差秒数
      let leave3 = leave2 % (60 * 1000); //计算分钟数后剩余的毫秒数
      let seconds = Math.round(leave3 / 1000);
      if (days || hours || minutes) {
        return (
          (days > 0 ? days + "天" : "") +
          (hours > 0 ? hours + "小时" : "") +
          (minutes > 0 ? minutes + "分" : "")
        );
      } else {
        return 0 + "分";
      }
    },
    initDatas() {
      this.listLoading = true;
      selectOvertimeOrder()
        .then((res) => {
          this.currentTotal = res.orderCount;
          this.orderList = res.orderList.slice(0, 10);
        })
        .finally(() => {
          this.listLoading = false;
        });
    },
    viewAllHandler() {
      const url = `${location.origin}${location.pathname}#/tenant/order/orderQuery/order/all?isOverTime=Y`;
      window.open(url, "_blank");
    },
  },
};
</script>

<style lang="less" scoped>
.order-device-list {
  padding: 24px;
  .title-container {
    .title-line {
      display: flex;
      align-items: center;
      .label-span {
        font-size: 16px;
        font-weight: bold;
        color: #3b454c;
        margin-left: 8px;
      }
    }
    .count-line {
      margin-top: 16px;
      margin-bottom: 24px;
      font-size: 40px;
      font-weight: bold;
      color: #3b454c;
    }
  }
  .detail-container {
    padding-top: 15px;
    border-top: 1px solid #ebeef5;
    height: 375px;
    margin-bottom: 10px;
    overflow: auto;
    .single-detail {
      height: 35px;
      line-height: 35px;
      display: flex;
      align-items: center;
      .tag-span {
        display: inline-block;
        padding: 0 5px;
        height: 24px;
        line-height: 24px;
        text-align: center;
        margin-right: 8px;
        border-radius: 4px;
        font-size: 14px;
        background: #fff7e9;
        color: #d7a24e;
      }
      .detail-span {
        flex: 1;
        width: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 14px;
        font-weight: 500;
        color: #1a4cec;
        cursor: pointer;
      }
      .date-span {
        font-size: 14px;
        font-weight: 400;
        color: #9099a9;
      }
      .date-span + .date-span {
        margin-left: 8px;
      }
    }
  }
}
</style>