var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "bms-working-staging" },
    [
      _c("div", { staticClass: "notice-line" }, [
        _c("div", { staticClass: "title-line" }, [
          _c("span", { staticClass: "label-span" }, [_vm._v(" 通知公告 ")]),
          _c("span", { staticClass: "link-span" }, [
            _vm.havePermissions.includes(
              "inotify$Service$tenantUserSetting$manageNotify"
            )
              ? _c(
                  "span",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.jumpHandler("newNotice")
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "el-icon-plus" }),
                    _c("span", [_vm._v(" 发布通知 ")]),
                  ]
                )
              : _vm._e(),
            _c(
              "span",
              {
                on: {
                  click: function ($event) {
                    return _vm.jumpHandler("moreNotice")
                  },
                },
              },
              [
                _c("span", [_vm._v(" 查看更多 ")]),
                _c("i", { staticClass: "el-icon-arrow-right" }),
              ]
            ),
          ]),
        ]),
        _c(
          "div",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.noticeLoading,
                expression: "noticeLoading",
              },
            ],
          },
          [
            _vm.noticeList.length
              ? _c(
                  "div",
                  { staticClass: "detail-line" },
                  [
                    _c(
                      "el-row",
                      { attrs: { gutter: 16 } },
                      _vm._l(_vm.noticeList, function (notice, index) {
                        return _c(
                          "el-col",
                          { key: index, attrs: { span: 8 } },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "single-notice",
                                on: {
                                  click: function ($event) {
                                    return _vm.openNotifyDetail(notice)
                                  },
                                },
                              },
                              [
                                _c("span", { staticClass: "left-part" }, [
                                  _c("img", {
                                    attrs: {
                                      src: require("@/assets/images/bms/notice-icon.png"),
                                      alt: "",
                                    },
                                  }),
                                  _c("span", [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(notice.notifyTitle) +
                                        "\n                "
                                    ),
                                  ]),
                                ]),
                                _c("span", { staticClass: "right-part" }, [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm._f("dateFormat")(
                                          notice.notifyTime,
                                          "YYYY-MM-DD"
                                        )
                                      ) +
                                      "\n              "
                                  ),
                                ]),
                              ]
                            ),
                          ]
                        )
                      }),
                      1
                    ),
                  ],
                  1
                )
              : _c("div", { staticClass: "no-data-container" }, [
                  _vm._v("暂无通知公告"),
                ]),
          ]
        ),
      ]),
      _c(
        "div",
        { staticClass: "personal-container" },
        [
          _c("div", { staticClass: "title-line" }, [_vm._v("个人")]),
          _c(
            "el-row",
            { staticClass: "detail-container", attrs: { gutter: 16 } },
            [
              _c("el-col", { attrs: { span: 8 } }, [_c("PersonalTodo")], 1),
              _c(
                "el-col",
                { attrs: { span: 16 } },
                [_c("PersonalSchedule")],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "project-container" }, [
        _c("div", { staticClass: "title-line" }, [_vm._v("工程")]),
        _c("div", { staticClass: "detail-container" }, [
          _c("div", { staticClass: "single-card" }, [_c("SmartDevices")], 1),
          _c("div", { staticClass: "single-card" }, [_c("OrderList")], 1),
          _c("div", { staticClass: "single-card" }, [_c("InspectionList")], 1),
        ]),
      ]),
      _c("InformationDetails", {
        attrs: {
          detailDrawer: _vm.detailDrawer,
          informationInfo: _vm.informationInfo,
        },
        on: {
          "update:detailDrawer": function ($event) {
            _vm.detailDrawer = $event
          },
          "update:detail-drawer": function ($event) {
            _vm.detailDrawer = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }