var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "view-all-button",
      on: {
        click: function ($event) {
          return _vm.$emit("click")
        },
      },
    },
    [_vm._v("\n  " + _vm._s(`查看全部(${_vm.count})`) + "\n")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }