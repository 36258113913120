<template>
  <div class="personal-schedule-component">
    <div class="title-line">
      <img
        class="title-img"
        src="@/assets/images/bms/personal-schedule-icon.png"
        alt=""
      />
      <span class="label-span"> 个人日程 </span>
    </div>
    <div class="detail-container" v-loading="listLoading">
      <div class="calendar-container">
        <el-calendar v-model="currentValue">
          <!-- 这里使用的是 2.5 slot 语法，对于新项目请使用 2.6 slot 语法-->
          <template slot="dateCell" slot-scope="{ date, data }">
            <div class="first-line">
              {{ singleDateRender(data) }}
              <span class="today-span" v-show="isToday(date)">今日</span>
            </div>
            <!-- <div class="follow-span" v-show="isToday(date)">跟进</div> -->
            <div v-if="checkInspection(date).length < 3">
              <div
                class="inspection-span"
                v-for="(item, index) in checkInspection(date)"
                :key="index"
              >
                巡检
              </div>
            </div>
            <div v-else>
              <div class="inspection-span">巡检</div>
              <div class="number-span">
                {{ `+${checkInspection(date).length - 1}` }}
              </div>
            </div>
            <!-- <div class="notice-span">通知</div> -->
          </template>
        </el-calendar>
      </div>
      <div class="right-part">
        <div class="item-line">
          <span
            v-for="(item, index) in itemList"
            :key="index"
            class="single-item"
          >
            <span
              class="item-icon"
              :style="{
                background: item.color,
              }"
            ></span>
            <span class="label-span">
              {{ item.label }}
            </span>
          </span>
        </div>
        <div>
          <div class="list-container" v-if="listDatas.length">
            <div
              class="single-item"
              v-for="(item, index) in listDatas"
              :key="index"
            >
              <div class="title-line">
                {{ timeTitleRender(item.date) }}
              </div>
              <div
                :class="[
                  'single-detail',
                  `single-detail-${TypeMaps[sub.systemName]}`,
                ]"
                v-for="(sub, subIndex) in item.list"
                :key="subIndex"
                @click="jumpLink(sub)"
              >
                <span class="detail-span">
                  {{ sub.exmTitle }}
                </span>
                <span class="date-span">
                  {{ `${sub.startTime}-${sub.endTime}` }}
                </span>
              </div>
            </div>
          </div>
          <div v-else>
            <el-empty
              class="no-data-container"
              description="暂无数据"
            ></el-empty>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getPersonalSchedule } from "@/api/ruge/bms/integratedManagementPlatform/workingStaging.js";
import { dateFormat } from "@/filters";
const TypeMaps = {
  巡检: "inspection",
};
const WeekMap = {
  0: "星期天",
  1: "星期一",
  2: "星期二",
  3: "星期三",
  4: "星期四",
  5: "星期五",
  6: "星期六",
};
export default {
  name: "personalScheduleComponent",
  data() {
    return {
      TypeMaps,
      existMap: null,
      currentValue: null,
      listLoading: false,
      currentTotal: 0,
      hasItemDates: [],
      listDatas: [
        // {
        //   title: "2月6日 星期一",
        //   list: [
        //     {
        //       detail: "一期潜在租户跟进",
        //       date: "8:30-12:00",
        //       type: "follow",
        //     },
        //   ],
        // },
      ],
      itemList: [
        {
          label: "跟进",
          color: "#7BE188",
        },
        {
          label: "巡检",
          color: "#FFE57C",
        },
        {
          label: "通知",
          color: "#FBACA3",
        },
      ],
    };
  },
  watch: {
    currentValue: {
      handler(value) {
        if (dateFormat(value, "YYYY-MM") !== this.existMap) {
          this.initDatas(dateFormat(value, "YYYY-MM"));
        }
        // 切换日期时也要重新刷一下右边详情list
        this.loadListDatas(value);
      },
    },
  },
  created() {
    this.initDatas();
  },
  methods: {
    singleDateRender(data) {
      const dateStr = data.day.split("-").slice(2).join("-");
      return dateStr.startsWith("0") ? dateStr.slice(1, 2) : dateStr;
    },
    jumpLink({ systemName, exmDetailLink }) {
      if (systemName === "工单") {
        const url = `${location.origin}${location.pathname}#/iot/orderQuery/detail?orderId=${exmDetailLink}`;
        window.open(url, "_blank");
      } else if (systemName === "巡检") {
        const url = `${location.origin}${location.pathname}#/tenant/inspection/inspectionSearch/detail?inspectionJobId=${exmDetailLink}`;
        window.open(url, "_blank");
      }
    },
    timeTitleRender(date) {
      let resultStr = dateFormat(date, "MM-DD");
      resultStr += ` ${WeekMap[new Date(date).getDay()]}`;
      return resultStr;
    },
    checkInspection(date) {
      const currentDate = dateFormat(date, "YYYY-MM-DD");
      let resultList = [];
      for (let item in this.hasItemDates) {
        if (item === currentDate) {
          if (this.hasItemDates[item] && this.hasItemDates[item].length) {
            for (let i = 0; i < this.hasItemDates[item].length; i++) {
              if (this.hasItemDates[item][i].systemName === "巡检") {
                resultList.push(this.hasItemDates[item][i]);
              }
            }
          }
        }
      }
      return resultList;
    },
    initDatas(month) {
      this.listLoading = true;
      const requestDate = month || dateFormat(new Date(), "YYYY-MM");
      getPersonalSchedule({
        month: requestDate,
      })
        .then((res) => {
          this.hasItemDates = res;
          this.loadListDatas();
        })
        .finally(() => {
          this.existMap = requestDate;
          this.listLoading = false;
        });
    },
    loadListDatas(date) {
      this.listDatas = [];
      let timeStamp = date || new Date();
      let currentDate = new Date(dateFormat(timeStamp, "YYYY-MM-DD")).getTime();
      // 解决for in 遍历顺序错误的问题
      const itemKeyList = Object.keys(this.hasItemDates).sort();
      itemKeyList.forEach((item) => {
        const itemStamp = new Date(item).getTime();
        if (itemStamp >= currentDate) {
          let temp = {
            date: itemStamp,
            list: this.hasItemDates[item],
          };
          this.listDatas.length < 3 && this.listDatas.push(temp);
        }
      });
    },
    isToday(date) {
      const currentDate = new Date();
      const itemStamp = date.getTime();
      const startStemp = new Date(
        `${currentDate.getFullYear()}-${
          currentDate.getMonth() + 1
        }-${currentDate.getDate()} 00:00:00`
      );
      const endStamp = new Date(
        `${currentDate.getFullYear()}-${
          currentDate.getMonth() + 1
        }-${currentDate.getDate()} 23:59:59`
      );
      if (startStemp < itemStamp && itemStamp < endStamp) {
        return true;
      }
      return false;
    },
    viewAllHandler() {},
  },
};
</script>

<style lang="less" scoped>
.personal-schedule-component {
  background: #ffffff;
  border-radius: 4px;
  height: 100%;
  padding: 24px;
  .title-line {
    display: flex;
    align-items: center;
    .title-img {
      margin-right: 8px;
    }
    .label-span {
      font-size: 16px;
      font-weight: bold;
      color: #3b454c;
    }
  }
  .detail-container {
    display: flex;
    .calendar-container {
      height: 500px;
      width: 600px;
      .first-line {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .today-span {
          width: 40px;
          text-align: center;
          height: 14px;
          font-size: 12px;
          font-weight: 400;
          background: #3b454c;
          color: #ffffff;
          display: inline-block;
          border-radius: 4px;
          line-height: 14px;
        }
      }
      .follow-span {
        width: 62px;
        text-align: center;
        height: 16px;
        font-size: 12px;
        font-weight: 400;
        background: #fbaca3;
        color: #3b454c;
        display: inline-block;
        border-radius: 4px;
        line-height: 16px;
      }
      .inspection-span {
        width: 100%;
        text-align: center;
        height: 16px;
        font-size: 12px;
        font-weight: 400;
        background: #ffe57c;
        color: #3b454c;
        display: inline-block;
        border-radius: 4px;
        line-height: 16px;
      }
      .number-span {
        width: 100%;
        text-align: center;
        height: 16px;
        font-size: 12px;
        font-weight: 400;
        background: #ffffff;
        color: #3b454c;
        border: 1px solid #e4e7ed;
        display: inline-block;
        border-radius: 4px;
        line-height: 16px;
        margin-top: 3px;
      }
      ::v-deep .el-calendar {
        .el-calendar__header {
          padding-left: 0;
          .el-calendar__title {
            font-weight: 600;
          }
        }
        .el-calendar__body {
          padding-left: 0;
          padding-bottom: 0;
          .el-backtop,
          .el-calendar-table td.is-today {
            color: #1a4cec;
          }
        }
      }
      ::v-deep .el-calendar-table {
        .el-calendar-table__row td {
          // width: 80px;
          height: 50px;
        }
        thead {
          height: 40px;
        }
      }
      ::v-deep .el-calendar-day {
        height: 67px !important;
        border: none;
      }
      ::v-deep .el-calendar__header {
        padding-bottom: 0;
        border-bottom: none;
      }
      ::v-deep .el-calendar-table th {
        // width: 80px;
        height: 40px !important;
        text-align: left;
        padding-left: 8px;
      }
    }
    .right-part {
      width: calc(100% - 600px);
      .item-line {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .single-item {
          display: flex;
          align-items: center;
          margin-left: 16px;
          .item-icon {
            display: inline-block;
            width: 8px;
            height: 8px;
            border-radius: 2px;
            margin-right: 4px;
          }
          .label-span {
            font-size: 14px;
            font-weight: 500;
            color: #9099a9;
          }
        }
      }
      .list-container {
        margin-top: 50px;
        margin-bottom: 15px;
        height: 420px;
        overflow: auto;
        .single-item {
          margin-top: 32px;
          .title-line {
            font-size: 14px;
            font-weight: 500;
            color: #9099a9;
            border-bottom: 1px solid #ebeef5;
            height: 24px;
            line-height: 24px;
          }
          .single-detail {
            cursor: pointer;
            height: 24px;
            margin-top: 8px;
            line-height: 24px;
            position: relative;
            padding-left: 12px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .detail-span {
              font-size: 14px;
              font-weight: 500;
              color: #3b454c;
              flex: 1;
              width: 0;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              color: #1a4cec;
            }
            .date-span {
              font-size: 14px;
              font-weight: 400;
              color: #9099a9;
            }
          }
          .single-detail-follow {
            &::before {
              content: "";
              display: inline-block;
              width: 8px;
              height: 24px;
              position: absolute;
              top: 0;
              left: 0;
              background: #7be188;
              border-radius: 2px;
            }
          }
          .single-detail-inspection {
            &::before {
              content: "";
              display: inline-block;
              width: 8px;
              height: 24px;
              position: absolute;
              top: 0;
              left: 0;
              background: #ffe57c;
              border-radius: 2px;
            }
          }
          .single-detail-notice {
            &::before {
              content: "";
              display: inline-block;
              width: 8px;
              height: 24px;
              position: absolute;
              top: 0;
              left: 0;
              background: #fbaca3;
              border-radius: 2px;
            }
          }
        }
      }
      .no-data-container {
        height: 485px;
      }
    }
  }
}
</style>