<template>
  <div class="bms-working-staging">
    <div class="notice-line">
      <div class="title-line">
        <span class="label-span"> 通知公告 </span>
        <span class="link-span">
          <span
            @click="jumpHandler('newNotice')"
            v-if="
              havePermissions.includes(
                'inotify$Service$tenantUserSetting$manageNotify'
              )
            "
          >
            <i class="el-icon-plus"></i>
            <span> 发布通知 </span>
          </span>
          <span @click="jumpHandler('moreNotice')">
            <span> 查看更多 </span>
            <i class="el-icon-arrow-right"></i>
          </span>
        </span>
      </div>
      <div v-loading="noticeLoading">
        <div class="detail-line" v-if="noticeList.length">
          <el-row :gutter="16">
            <el-col
              :span="8"
              v-for="(notice, index) in noticeList"
              :key="index"
            >
              <div class="single-notice" @click="openNotifyDetail(notice)">
                <span class="left-part">
                  <img src="@/assets/images/bms/notice-icon.png" alt="" />
                  <span>
                    {{ notice.notifyTitle }}
                  </span>
                </span>
                <span class="right-part">
                  {{ notice.notifyTime | dateFormat("YYYY-MM-DD") }}
                </span>
              </div>
            </el-col>
          </el-row>
        </div>
        <div v-else class="no-data-container">暂无通知公告</div>
      </div>
    </div>
    <div class="personal-container">
      <div class="title-line">个人</div>
      <el-row :gutter="16" class="detail-container">
        <el-col :span="8">
          <PersonalTodo />
        </el-col>
        <el-col :span="16">
          <PersonalSchedule />
        </el-col>
      </el-row>
    </div>
    <!-- <div class="business-container">
      <div class="title-line">招商</div>
      <el-row :gutter="16" class="detail-container">
        <el-col :span="16">
          <RentSituation />
        </el-col>
        <el-col :span="8">
          <ContractExpiration />
        </el-col>
      </el-row>
    </div> -->
    <div class="project-container">
      <div class="title-line">工程</div>
      <div class="detail-container">
        <div class="single-card">
          <!-- 智能设备监测 -->
          <SmartDevices />
        </div>
        <div class="single-card">
          <!-- 工单超时提醒 -->
          <OrderList />
        </div>
        <div class="single-card">
          <!-- 巡检超时提醒 -->
          <InspectionList />
        </div>
      </div>
    </div>
    <InformationDetails
      :detailDrawer.sync="detailDrawer"
      :informationInfo="informationInfo"
    />
  </div>
</template>

<script>
import PersonalTodo from "./components/personalTodo.vue";
import PersonalSchedule from "./components/personalSchedule.vue";
import RentSituation from "./components/rentSituation.vue";
import ContractExpiration from "./components/contractExpiration.vue";
import SmartDevices from "./components/smartDevices.vue";
import OrderList from "./components/orderList.vue";
import InspectionList from "./components/inspectionList.vue";
import { getRecordInformation } from "@/api/ruge/notify/notify";
import { mapGetters } from "vuex";
import InformationDetails from "@/components/Information/components/informationDetails";
export default {
  name: "bmsWorkingStaging",
  components: {
    PersonalTodo,
    PersonalSchedule,
    RentSituation,
    ContractExpiration,
    SmartDevices,
    OrderList,
    InspectionList,
    InformationDetails,
  },
  data() {
    return {
      detailDrawer: false,
      informationInfo: null,
      noticeLoading: false,
      noticeList: [],
    };
  },
  computed: {
    ...mapGetters(["havePermissions"]),
  },
  created() {
    this.getNoticeList();
    console.log(
      "havePermissions",
      this.havePermissions.includes(
        "inotify$Service$tenantUserSetting$manageNotify"
      )
    );
  },
  methods: {
    openNotifyDetail(item) {
      this.informationInfo = item;
      this.detailDrawer = true;
    },
    jumpHandler(type) {
      let url = "";
      switch (type) {
        case "newNotice":
          url = `${location.origin}${location.pathname}#/tenant/notify/list`;
          break;
        case "moreNotice":
          url = `${location.origin}${location.pathname}#/personal/common?id=9`;
          break;
      }
      url && window.open(url, "_blank");
    },
    getNoticeList() {
      this.noticeLoading = true;
      getRecordInformation()
        .then((res) => {
          this.noticeList = res.rows.slice(0, 3);
        })
        .finally(() => {
          this.noticeLoading = false;
        });
    },
  },
};
</script>

<style lang="less" scoped>
.bms-working-staging {
  padding: 32px 16px 32px 40px;
  height: calc(100vh - 60px);
  overflow: auto;
  .notice-line {
    .title-line {
      display: flex;
      justify-content: space-between;
      .label-span {
        font-size: 20px;
        font-weight: bold;
        color: #000000;
      }
      .link-span {
        font-size: 14px;
        font-weight: 400;
        color: #1a4cec;
        i {
          font-size: 14px;
          font-weight: bold;
        }
        & > span {
          cursor: pointer;
          margin-left: 15px;
        }
      }
    }
    .detail-line {
      margin-top: 16px;
      .single-notice {
        cursor: pointer;
        display: flex;
        padding: 0 16px;
        justify-content: space-between;
        align-items: center;
        height: 56px;
        line-height: 56px;
        background: #ffffff;
        border-radius: 4px;
        .left-part {
          display: flex;
          align-items: center;
          font-size: 14px;
          font-weight: 500;
          color: #3b454c;
          img {
            margin-right: 8px;
          }
        }
        .right-part {
          font-size: 14px;
          font-weight: 400;
          color: #9099a9;
        }
      }
      .single-notice + .single-notice {
        margin-left: 16px;
      }
    }
    .no-data-container {
      font-size: 14px;
      margin-top: 16px;
      display: flex;
      padding: 0 16px;
      justify-content: center;
      align-items: center;
      height: 56px;
      line-height: 56px;
      background: #ffffff;
      border-radius: 4px;
    }
  }
  .personal-container {
    margin-top: 40px;
    .title-line {
      font-size: 20px;
      font-weight: bold;
      color: #000000;
    }
    .detail-container {
      margin-top: 16px;
      height: 573px;
    }
  }
  .business-container {
    margin-top: 40px;
    .title-line {
      font-size: 20px;
      font-weight: bold;
      color: #000000;
    }
    .detail-container {
      margin-top: 16px;
      height: 517px;
      // .left-part {
      //   flex: 2;
      //   margin-right: 16px;
      //   background: #ffffff;
      //   border-radius: 4px;
      // }
      // .right-part {
      //   flex: 1;
      //   background: #ffffff;
      //   border-radius: 4px;
      // }
    }
  }
  .project-container {
    margin-top: 40px;
    .title-line {
      font-size: 20px;
      font-weight: bold;
      color: #000000;
    }
    .detail-container {
      margin-top: 16px;
      display: flex;
      height: 573px;
      .single-card {
        flex: 1;
        background: #ffffff;
        border-radius: 4px;
      }
      .single-card + .single-card {
        margin-left: 16px;
      }
    }
  }
}
</style>