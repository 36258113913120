<template>
  <div class="rent-situation-component">
    <div class="title-line">
      <span class="left-part">
        <img
          class="title-img"
          src="@/assets/images/bms/rent-situation-icon.png"
          alt=""
        />
        <span class="label-span"> 租赁情况 </span>
      </span>
      <span class="date-span"> 更新时间 {{ updateTime }} </span>
    </div>
    <div class="chart-container" v-loading="listLoading">
      <div
        class="single-progress"
        v-for="(item, index) in progressList"
        :key="index"
      >
        <div class="label-line">
          <span class="label-span">
            {{ item.projectName }}
          </span>
        </div>
        <el-progress
          type="dashboard"
          :width="212"
          stroke-linecap="round"
          :format="format"
          :stroke-width="16"
          :percentage="item.rentalRate"
          :color="colors"
        ></el-progress>
        <div class="already-rent-line">
          <span class="title-span"></span>
          <span class="label-span">已出租面积</span>
          <span class="value-span">{{ item.leasedArea }}万㎡</span>
        </div>
        <div class="total-rent-line">
          <span class="title-span"></span>
          <span class="label-span">总租赁面积</span>
          <span class="value-span">{{ item.totalArea }}万㎡</span>
        </div>
        <div class="compare-line">
          <span class="label-span">较上月底</span>
          <img
            :src="
              require(`@/assets/images/bms/rent-state-${item.annuallyFloat}.png`)
            "
            alt=""
          />
          <span class="value-span">{{ item.annuallyGrowthRate }}%</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { findProjectRentalByMonth } from "@/api/ruge/bms/integratedManagementPlatform/workingStaging.js";
import { dateFormat } from "@/filters/index.js";
export default {
  name: "rentSituationComponent",
  data() {
    return {
      updateTime: "2023-02-13 09:00",
      listLoading: false,
      progressList: [],
      colors: [
        { color: "#004CFF20", percentage: 1 },
        { color: "#004CFF", percentage: 100 },
      ],
    };
  },
  created() {
    this.initDatas();
  },
  methods: {
    initDatas() {
      this.listLoading = true;
      findProjectRentalByMonth()
        .then((res) => {
          this.progressList = res.map((item) => {
            item.leasedArea = (item.leasedArea / 10000).toFixed(2);
            item.totalArea = (item.totalArea / 10000).toFixed(2);
            return item;
          });
          this.freshUpdateTime(res[0].lastUpdateDate);
        })
        .finally(() => {
          this.listLoading = false;
        });
    },
    freshUpdateTime(date) {
      this.updateTime = date
        ? dateFormat(date, "YYYY-MM-DD HH:mm")
        : dateFormat(new Date(), "YYYY-MM-DD HH:mm");
    },
    format(percentage) {
      const fixedPercentage = percentage.toFixed(0);
      return `${fixedPercentage}% \n 出租率`;
    },
  },
};
</script>

<style lang="less" scoped>
.rent-situation-component {
  padding: 24px;
  background: #ffffff;
  border-radius: 4px;
  height: 517px;
  .title-line {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left-part {
      display: flex;
      align-items: center;
      .title-img {
        margin-right: 8px;
      }
      .label-span {
        font-size: 16px;
        font-weight: bold;
        color: #3b454c;
      }
    }
    .date-span {
      font-size: 14px;
      font-weight: 400;
      color: #9099a9;
    }
  }
  .chart-container {
    margin-top: 24px;
    border-top: 1px solid #ebeef5;
    display: flex;
    .single-progress {
      flex: 1;
      margin-top: 46px;
      text-align: center;
      .label-line {
        margin-bottom: 21px;
        text-align: center;
        .label-span {
          display: inline-block;
          padding: 0 8px;
          height: 36px;
          line-height: 36px;
          background: #eef3fd;
          border-radius: 4px;
          font-size: 14px;
          font-weight: 400;
          color: #175dff;
        }
      }
      .already-rent-line {
        margin-bottom: 8px;
        .title-span {
          display: inline-block;
          width: 28px;
          height: 8px;
          border-radius: 4px;
          background: #175dff;
          margin-right: 12px;
        }
        .label-span {
          font-size: 14px;
          font-weight: 400;
          color: #9099a9;
          margin-right: 8px;
        }
        .value-span {
          font-size: 14px;
          font-weight: 400;
          color: #3b454c;
          width: 85px;
          display: inline-block;
        }
      }
      .total-rent-line {
        margin-bottom: 12px;
        .title-span {
          display: inline-block;
          width: 28px;
          height: 8px;
          border-radius: 4px;
          background: #eef3fd;
          margin-right: 12px;
        }
        .label-span {
          font-size: 14px;
          font-weight: 400;
          color: #9099a9;
          margin-right: 8px;
        }
        .value-span {
          font-size: 14px;
          font-weight: 400;
          color: #3b454c;
          width: 85px;
          display: inline-block;
        }
      }
      .compare-line {
        display: flex;
        align-items: center;
        justify-content: center;
        .label-span {
          font-size: 14px;
          font-weight: 400;
          color: #9099a9;
          margin-right: 8px;
        }
        .value-span {
          font-size: 14px;
          font-weight: 400;
          color: #000000;
        }
      }
    }
  }
  ::v-deep .el-progress__text {
    white-space: pre-wrap;
    font-size: 18px !important;
    line-height: 24px;
    font-weight: bold;
  }
}
</style>