var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "contract-expiration-component" }, [
    _vm._m(0),
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.listLoading,
            expression: "listLoading",
          },
        ],
      },
      [
        _vm.noticeList.length
          ? _c("div", [
              _c(
                "div",
                { staticClass: "detail-container" },
                _vm._l(_vm.noticeList, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "single-notice",
                      on: {
                        click: function ($event) {
                          return _vm.jumpToRspace(item)
                        },
                      },
                    },
                    [
                      _c("span", { staticClass: "tag-span" }, [
                        _vm._v(
                          "\n            " + _vm._s(item.store) + "\n          "
                        ),
                      ]),
                      _c("span", { staticClass: "label-span" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(`${item.source} ${item.tenant}`) +
                            "\n          "
                        ),
                      ]),
                      _c("span", { staticClass: "date-span" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(`剩余${item.daysLeft}天`) +
                            "\n          "
                        ),
                      ]),
                    ]
                  )
                }),
                0
              ),
              _c(
                "div",
                { staticClass: "view-all-line" },
                [
                  _c("ViewAllButton", {
                    attrs: { count: _vm.currentTotal },
                    on: { click: _vm.viewAllHandler },
                  }),
                ],
                1
              ),
            ])
          : _c(
              "div",
              [_c("el-empty", { attrs: { description: "暂无数据" } })],
              1
            ),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title-line" }, [
      _c("img", {
        staticClass: "title-img",
        attrs: {
          src: require("@/assets/images/bms/contract-expiration-icon.png"),
          alt: "",
        },
      }),
      _c("span", { staticClass: "label-span" }, [_vm._v(" 合同到期提醒 ")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }