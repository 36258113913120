<template>
  <div class="personla-todo-list">
    <div class="title-line">
      <img
        class="title-img"
        src="@/assets/images/bms/personal-todo-icon.png"
        alt=""
      />
      <span class="label-span"> 个人待办事项 </span>
    </div>
    <div v-loading="listLoading">
      <div v-if="todoList.length">
        <div class="detail-container">
          <div
            class="single-item"
            v-for="(item, index) in todoList"
            :key="index"
            @click="jumpToDetails(item)"
          >
            <span class="tag-span">
              {{ item.systemName }}
            </span>
            <span class="detail-span">
              {{ item.exmTitle }}
            </span>
            <span class="date-span">
              {{ item.releaseDate | dateFormat("YYYY-MM-DD HH:mm") }}
            </span>
          </div>
        </div>
        <!-- <div class="view-all-line">
          <ViewAllButton :count="currentTotal" @click="viewAllHandler" />
        </div> -->
      </div>
      <div v-else>
        <el-empty class="no-data-container" description="暂无数据"></el-empty>
      </div>
    </div>
  </div>
</template>

<script>
import { getPersonalTodoList } from "@/api/ruge/workflow/workflow.js";
import ViewAllButton from "../../components/ViewAllButton";
export default {
  name: "personlaTodoList",
  components: {
    ViewAllButton,
  },
  data() {
    return {
      listLoading: false,
      currentTotal: 0,
      todoList: [],
    };
  },
  created() {
    this.initDatas();
  },
  methods: {
    jumpToDetails({ systemName, exmDetailLink }) {
      if (systemName === "工单") {
        const url = `${location.origin}${location.pathname}#/iot/orderQuery/detail?orderId=${exmDetailLink}`;
        window.open(url, "_blank");
      } else if (systemName === "巡检") {
        const url = `${location.origin}${location.pathname}#/tenant/inspection/inspectionSearch/detail?inspectionJobId=${exmDetailLink}`;
        window.open(url, "_blank");
      }
    },
    initDatas() {
      this.listLoading = true;
      getPersonalTodoList({
        current: 1,
        rowCount: 100,
      })
        .then((res) => {
          this.todoList = res.rows;
          this.currentTotal = res.total;
        })
        .finally(() => {
          this.listLoading = false;
        });
    },
    viewAllHandler() {
      const url = `${location.origin}${location.pathname}#/tenant/order/orderQuery/order/all`;
      window.open(url, "_blank");
    },
  },
};
</script> 

<style lang="less" scoped>
.personla-todo-list {
  background: #ffffff;
  border-radius: 4px;
  height: 100%;
  padding: 24px;
  .title-line {
    display: flex;
    align-items: center;
    .title-img {
      margin-right: 8px;
    }
    .label-span {
      font-size: 16px;
      font-weight: bold;
      color: #3b454c;
    }
  }
  .detail-container {
    padding: 16px 0;
    margin-top: 24px;
    border-top: 1px solid #ebeef5;
    height: 462px;
    margin-bottom: 15px;
    overflow: auto;
    .single-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 35px;
      line-height: 35px;
      padding-right: 8px;
      .tag-span {
        display: inline-block;
        width: 48px;
        line-height: 24px;
        height: 24px;
        background: #ebeef5;
        font-size: 14px;
        font-weight: 400;
        color: #3b454c;
        text-align: center;
        border-radius: 2px;
      }
      .detail-span {
        flex: 1;
        width: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 14px;
        font-weight: 500;
        color: #1a4cec;
        cursor: pointer;
        margin-left: 5px;
      }
    }
    .single-item + .single-item {
      margin-top: 12px;
    }
  }
  .no-data-container {
    height: 500px;
  }
}
</style>