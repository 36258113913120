var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "personla-todo-list" }, [
    _vm._m(0),
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.listLoading,
            expression: "listLoading",
          },
        ],
      },
      [
        _vm.todoList.length
          ? _c("div", [
              _c(
                "div",
                { staticClass: "detail-container" },
                _vm._l(_vm.todoList, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "single-item",
                      on: {
                        click: function ($event) {
                          return _vm.jumpToDetails(item)
                        },
                      },
                    },
                    [
                      _c("span", { staticClass: "tag-span" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(item.systemName) +
                            "\n          "
                        ),
                      ]),
                      _c("span", { staticClass: "detail-span" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(item.exmTitle) +
                            "\n          "
                        ),
                      ]),
                      _c("span", { staticClass: "date-span" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm._f("dateFormat")(
                                item.releaseDate,
                                "YYYY-MM-DD HH:mm"
                              )
                            ) +
                            "\n          "
                        ),
                      ]),
                    ]
                  )
                }),
                0
              ),
            ])
          : _c(
              "div",
              [
                _c("el-empty", {
                  staticClass: "no-data-container",
                  attrs: { description: "暂无数据" },
                }),
              ],
              1
            ),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title-line" }, [
      _c("img", {
        staticClass: "title-img",
        attrs: {
          src: require("@/assets/images/bms/personal-todo-icon.png"),
          alt: "",
        },
      }),
      _c("span", { staticClass: "label-span" }, [_vm._v(" 个人待办事项 ")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }