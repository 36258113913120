export const Contants = {
  alarmLevel: {
    info: '提示',
    warn: '一般',
    error: '严重',
  },
};

export const rspacePathMap = {
  DEV: 'https://sit.rlinkiot.com/rspace/#',
  SIT: 'https://sit.rlinkiot.com/rspace/#',
  UAT: 'https://uat.rlinkiot.com/rspace/#',
  PROD: 'https://prod.rlinkiot.com/rspace/#',
};
